import logo from './rwa.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          reindeerwifat
        </p>
        <a
            className="App-link"
            href="https://solana.fm/address/EaCdrkSmjkZNkgaap7uTZTL8bcVVXAzjvQHR28qFbvMs"
            target="_blank"
            rel="noopener noreferrer"
        >
          EaCdrkSmjkZNkgaap7uTZTL8bcVVXAzjvQHR28qFbvMs
        </a>
        <br/>
        <a
          className="App-link"
          href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=EaCdrkSmjkZNkgaap7uTZTL8[%E2%80%A6]zjvQHR28qFbvMs&inputAmount=1&fixed=in"
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy on Raydium
        </a>
        <br/>
        <a
            className="App-link"
            href="https://birdeye.so/token/EaCdrkSmjkZNkgaap7uTZTL8bcVVXAzjvQHR28qFbvMs/HQcY5n2zP6rW74fyFEhWeBd3LnJpBcZechkvJpmdb8cx?chain=solana"
            target="_blank"
            rel="noopener noreferrer"
        >
          Birdeye
        </a>
      </header>
    </div>
  );
}

export default App;
